import React from 'react';

export default function User() {
  return (
    <>
      <div className='flex m-2 mb-6'>
        <div className='w-[35px] h-[35px] bg-darkgray rounded-full mr-2'></div>
        <p className='align-middle'>nickname</p>
      </div>
    </>
  );
}
