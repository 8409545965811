import React from 'react';
import Comment from './Comment';

export default function CommentList() {
  return (
    <>
      <div className='m-2 text-sm'>
        <Comment></Comment>
      </div>
    </>
  );
}
