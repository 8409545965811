import React from 'react';

export default function Comment() {
  return (
    <>
      <div>
        <div>이름 댓글</div>
      </div>
    </>
  );
}
