import React from 'react';
import FeedTab from '../components/Feed/FeedTab';

export default function Feed() {
  return (
    <div>
      <FeedTab />
    </div>
  );
}
