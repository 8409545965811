import React from 'react';
import User from '../components/Feed/User';
import FeedPostForm from '../components/Feed/FeedPostForm';
export default function FeedWritePage() {
  return (
    <>
      <User />
      <FeedPostForm />
    </>
  );
}
